import { useTranslation } from 'react-i18next'
import Content from '../../common/components/v2/Content/Content'
import Footer from '../../common/components/v2/Footer/Footer'
import Header from '../../common/components/v2/Header/Header'
import './index.scss'
// import MobileDesktopModeInstructions from './components/MobileDesktopModeInstructions/MobileDesktopModeInstructions'
import ConnectBox from './components/ConnectBox/ConnectBox'
import { ConnectContextProvider } from '../../contexts/ConnectContext/ConnectContext'

function Home() {
  const { t } = useTranslation()
  return (
    <div id="home-content">
      {/* <MobileDesktopModeInstructions /> */}
      <Header />
      <Content
        leftSideChildren={
          <>
            <h1>{t('home_left_side_heading1')} &#x1F60D;&#128591;&#129392; </h1>
            <p>{t('home_left_side_subheader')}</p>
            <ul className="advantages">
              <li>{t('home_reached_accounts')}</li>
              <li>{t('home_views')}</li>
              <li>{t('home_interactions')}</li>
              <li>{t('home_interactions_previous_next_touch')}</li>
              <li>{t('home_interactions_left_post')}</li>
            </ul>
          </>
        }
        rightSideChildren={
          <>
            <ConnectContextProvider>
              <ConnectBox />
            </ConnectContextProvider>
          </>
        }
      />
      <Footer />
    </div>
  )
}

export default Home
