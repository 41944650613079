import { useEffect, useState } from 'react'
import Footer from '../../common/components/v2/Footer/Footer'
import Header from '../../common/components/v2/Header/Header'
import i18n from '../../languages/i18n'
import TermsOfUseEN from './TermsOfUseEN'
import TermsOfUsePT from './TermsOfUsePT'
import './index.scss'

function useForceUpdate() {
  const [, setValue] = useState(0)
  return () => setValue((value) => value + 1)
}

function TermsOfUse() {
  const [lang, setLang] = useState('en')
  const forceUpdate = useForceUpdate()
  useEffect(() => {
    if (!i18n.language) return
    const detectedLang = ['en', 'en_US', 'en-US'].includes(i18n.language)
      ? 'en'
      : 'pt'
    setLang(detectedLang)
    forceUpdate()
  }, [i18n.language])

  return (
    <>
      <Header />
      <div className="contract-page">
        <div className="contract-text">
          {lang === 'en' && <TermsOfUseEN />}
          {lang === 'pt' && <TermsOfUsePT />}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TermsOfUse
