const pt = {
  protection: 'Proteção',
  data: 'Dados',
  connect: 'Conecte',
  google_connect_with: 'Conecte com Google Analytics',
  google_connect_description:
    'Faça o connect com o Google Analytics para acompanhar com mais facilidade as conversões geradas por seus influenciadores de maneira segura.',
  google_monitor_description: 'Monitore o resultado das suas campanhas',
  google_arrow_connect: 'Faça o connect',
  google_arrow_track: 'Monitore conversões',
  google_arrow_optimize: 'Otimize resultados',
  google_follow: 'Monitore com detalhes as suas conversões',
  mobile_desktop_mode_important: 'Importante',
  mobile_desktop_mode_back: 'Voltar',
  mobile_desktop_mode: 'Modo Desktop',
  mobile_desktop_for_computers: 'Para Computadores',
  mobile_desktop_site: 'Site do Desktop',
  mobile_desktop_request_desktop_site: 'Solicitar Site Móvel',
  mobile_desktop_mode_instructions_to: 'Para fazer o',
  mobile_desktop_mode_instructions_or: 'ou',
  mobile_desktop_mode_instructions:
    'no Hypolake é necessário que você altere a do seu navegador no celular para a versão desktop/computador/site móvel acessando a opção de configurações nessa mesma tela.',
  mobile_desktop_mode_cant_find:
    'Se você não estiver encontrando uma dessas funções recomendamos baixar um dos seguintes navegadores:',
  mobile_desktop_mode_instructions_menu:
    'no Hypolake com sucesso acesse o menu localizado no canto superior direito do navegador representado por 3 bolinhas, selecione a opção',
  mobile_desktop_mode_instructions_menu_safari:
    'no Hypolake com sucesso acesse o menu no canto esquerdo da barra de busca do navegador representado por dois "As" (aA), selecione a opção',
  mobile_desktop_mode_instructions_try_again: ' tente se conectar novamente',
  footer_brands_heading: 'Marcas que usam o Hypolake',
  home_left_side_heading1: 'Chega de prints!',
  home_left_side_subheader:
    'Com o connect você não precisa mais enviar prints. A marca solicitante poderá acessar seus números de:',
  home_reached_accounts: 'Contas alcançadas',
  home_views: 'Visualizações',
  home_interactions: 'Interações',
  home_interactions_previous_next_touch: 'Toques de avançar e voltar',
  home_interactions_left_post: 'Sair do post',
  connect_box_select_network: 'Conecte-se com sua rede',
  connect_box_verify_the: 'Verifique os',
  connect_box_and_the: 'e a',
  connect_box_of_hypolake: 'do Hypolake',
  connect_button_tiktok: 'Conecte o TikTok',
  connect_button_instagram: 'Conecte o Instagram',
  connect_button_twitter: 'Conecte o Twitter',
  connect_canceled: 'Conecte cancelado',
  connect_waiting: 'Aguardado o conecte...',
  connect_validating_authorization: 'Aguarde, validando autorização...',
  connect_success: 'Conta conectada com sucesso!',
  connect_error: 'Não conseguimos conectar sua conta. Tente novamente.',
  connect_closed_popup:
    'Parece que você fechou o popup antes de finalizar o conecte. Tente novamente...',
  error: 'erro',
  footer_product: 'Produto',
  footer_resources: 'Recursos',
  footer_plan: 'Plano',
  footer_contact: 'Contato',
  terms_of_use: 'Termos de uso',
  privacy_policy: 'Política de privacidade',
  footer_rights_reserved: 'Todos os direitos reservados',
  visit_website: 'Visite o website',
  invalid_link:
    'URL inválida, por favor verifique o link de conecte e tente novamente',
  connect_accept_all_permissions: 'Você precisa aceitar todas as permissões.',
  loading: 'Carregando',
  connect_google_cant_reach:
    'Nos não conseguimos acessar sua conta do google Analytics',
  connect_google_plase_verify:
    'Por favor, verifique se você está se conectando com a conta correta.',
  connect_google_create_account:
    'Se sua conta está correta, crie uma conta acessando o',
  connect_google_and_try_again: 'e tente novamente',
  connect_google_sign_in: 'Entrar com google',
  'GA Account does not exists': 'A conta do GA não existe.',
}

export default pt
