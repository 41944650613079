import React, { createContext, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { extractParams } from '../../utils/payload'

const defaultValues = {}

const ConnectContext = createContext(defaultValues)

const ConnectContextProvider = ({ children }) => {
  const [statusConnect, setStatusConnect] = useState('')
  const [connectDataLoading, setConnectDataLoading] = useState(true)
  const [connectData, setConnectData] = useState({})
  const [network, setNetwork] = useState()

  useEffect(() => {
    setConnectData(extractParams())
    setConnectDataLoading(false)
  }, [])

  useEffect(() => {
    if (connectData.air_id) {
      setNetwork(connectData.air_id.split(':')[0])
    }
  }, [connectData])

  return (
    <ConnectContext.Provider
      value={{
        statusConnect,
        setStatusConnect,
        connectDataLoading,
        connectData,
        network,
      }}
    >
      {children}
    </ConnectContext.Provider>
  )
}

ConnectContextProvider.propTypes = {
  children: propTypes.node.isRequired,
}

export { ConnectContextProvider }
export default ConnectContext
