import api from '../../service'

export const connectInstagram = (clientId, accessToken) =>
  api.post('/external/instagram/connect/profile', {
    client_id: clientId,
    access_token: accessToken,
  })

export const connectTikTok = (clientID, tiktokCode) =>
  api.post('/external/tiktok/connect/profile', {
    code: tiktokCode,
    client_id: clientID,
  })

export const getTwitterConnectUrl = (clientId) =>
  api.get('/external/twitter/auth_url', {
    params: { callback_location: 'hypolake', client_id: clientId },
  })

export const connectTwitter = (airId, clientId, oauthToken, oauthVerifier) =>
  api.post('/external/twitter/connect', {
    air_id: airId,
    client_id: clientId,
    oauth_token: oauthToken,
    oauth_verifier: oauthVerifier,
  })
