import { useTranslation } from 'react-i18next'
import { getLink } from '../../../../languages/i18n'
import Logo from '../../../assets/images/logo.svg'
import './index.scss'

function Header() {
  const { t } = useTranslation()
  return (
    <header id="header">
      <a href={getLink('home')} rel="noreferrer" target="_blank">
        <img src={Logo} alt="Hypolake" width={167.35} />
      </a>
      <button
        className="visit-website-button"
        type="button"
        onClick={() => {
          window.open(getLink('home'), '_blank')
        }}
      >
        {t('visit_website')}
      </button>
    </header>
  )
}

export default Header
