function TermsOfUsePT() {
  return (
    <>
      <div>
        <br />
        <p>
          <strong>
            CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE USO DE SOFTWARE ON-LINE - SAAS
          </strong>
        </p>
        <p>
          De um lado, <strong>AIRSTRIP S/A</strong> , pessoa jurídica de direito
          privado, inscrita no CNPJ/MF sob o nº 13.597.093/0001-25, com sede na
          com sede na Avenida Eusébio Matoso nº 690, 6º andar, Pinheiros, São
          Paulo, SP, CEP 05423-000, doravante denominada{' '}
          <strong>AIRSTRIP</strong> , e de outro, a <strong>USUÁRIO</strong>{' '}
          devidamente identificada através do preenchimento dos dados do
          formulário on-line disponível na URL xxx, nos termos da lei 9.609/98,
          artigo 593 e seguintes do Código Civil, têm justo e acordado o quanto
          segue:
        </p>
        <br />
        <p>
          <strong>1. DO OBJETO:</strong>
        </p>
        <p>
          1.1 - O objeto deste contrato é a prestação de serviços não exclusivo
          de uso do software <em>on-line</em> de titularidade e desenvolvido
          exclusivamente pela <strong>AIRSTRIP</strong> , denominado{' '}
          <em>
            {' '}
            <strong>&quot;</strong>{' '}
            <strong>
              <strong>HYPOLAKE</strong>
            </strong>{' '}
            <strong>&quot;</strong>{' '}
          </em>
          , que tem por finalidade precípua possibilitar que a{' '}
          <strong>USUÁRIO</strong> faça o mapeamento da performance de usuários,
          por meio do uso de dados públicos coletados em perfis públicos das
          redes sociais<em>Instagram</em> e dados do Google Analytics, bem como
          o planejamento e análise de campanhas, e outras tarefas/atividades que
          eventualmente venham a ser integradas à ferramenta;
        </p>
        <p>
          1.2 - O plano contratado será aquele que a <strong>USUÁRIO</strong>{' '}
          escolher e que estão disponíveis na URL{' '}
          <a href="http://www.hypolake.com/planos">www.hypolake.com/planos</a>,
          com todas as suas especificações, preços, funcionalidades e demais
          características, sendo cobrados os valores praticados pela{' '}
          <strong>AIRSTRIP</strong>. O <strong>USUÁRIO</strong> declara que
          antes de aceitar os termos deste contrato, leu e entendeu todas as
          características do plano contratado, e que o plano atinge às suas
          expectativas e está de acordo com todas as informações prestadas, não
          havendo nenhuma dúvida que possa ser usada para rescisão injusta;
        </p>
        <p>
          1.3 – O uso do programa é exclusivo nas dependências do{' '}
          <strong>USUÁRIO</strong> , e os serviços não poderão ser cedidos,
          emprestados, transferidos, vendido, locado, sublocado ou sofrer
          qualquer tipo de uso por terceiros que não façam parte deste contrato,
          sob pena de cancelamento imediato;
        </p>
        <p>
          1.4 – Quando da contratação, a <strong>AIRSTRIP</strong> poderá
          identificar o endereço IP da conexão e o endereço da placa MAC da
          máquina do <strong>USUÁRIO</strong> , e não repassará esses dados para
          nenhum terceiro;
        </p>
        <br />
        <p>
          <strong>2. – DO USO DA PLATAFORMA:</strong>
        </p>
        <p>
          2.1 - Para o uso do programa{' '}
          <em>
            on-line__ <strong>HYPOLAKE</strong>{' '}
          </em>
          , é obrigatório que o <strong>USUÁRIO</strong> tenha uma conexão de
          internet e um computador que possibilite o acesso à{' '}
          <em>world wide web</em>. Em nenhuma hipótese o programa ou os serviços
          funcionarão off-line. É de conhecimento do <strong>USUÁRIO</strong>{' '}
          que o desempenho da plataforma{' '}
          <em>
            {' '}
            <strong>HYPOLAKE</strong>{' '}
          </em>{' '}
          poderá ser afetado em função da qualidade dos equipamentos usados e
          das conexões de internet do <strong>USUÁRIO</strong> e seus prepostos,
          bem como do uso simultâneo de outros programas, sem qualquer
          responsabilidade da <strong>AIRSTRIP</strong>. A plataforma não
          funcionará em smartphones;
        </p>
        <p>
          2.2 – Os serviços e o uso do programa serão prestados no estado em que
          se encontram no momento do uso, e é restrito às funcionalidades que
          estiverem disponíveis no momento do aceite on-line deste contrato,
          inclusive no que se refere às informações que dispõe e armazena. É
          vedada a realização pela <strong>USUÁRIO</strong> de qualquer
          modificação na plataforma, ainda que com a finalidade de incorporar
          plugins, melhoramentos, <em>upgrades</em> ou <em>updates</em>, os
          quais somente poderão ser realizados pela <strong>AIRSTRIP</strong> na
          qualidade de titular dos direitos econômicos e intelectuais;
        </p>
        <p>
          2.3 - O <strong>USUÁRIO</strong> poderá utilizar a plataforma e suas
          funcionalidades enquanto perdurar este contrato, e desde que esteja
          adimplente com os pagamentos das parcelas da licença, usando seu login
          e sua senha pessoais e intransferíveis, mediante acesso à plataforma
          através da URL ?. O <strong>USUÁRIO</strong> poderá conceder acesso ao
          ambiente da plataforma para outros usuários, usando o mesmo login,
          desde que sejam seus prepostos, vedado o acesso simultâneo, bem como a
          cessão dos dados de acesso. Em qualquer caso, a{' '}
          <strong>USUÁRIO</strong> será a única responsável por todos os
          eventuais atos praticados nesses seus acessos;
        </p>
        <p>
          2.4 - Todos os dados e informações coletados e/ou utilizados com o
          auxílio direto ou indireto da plataforma, bem como todo o conteúdo
          gerado em decorrência do uso da plataforma poderão ser usados em suas
          atividades empresariais, não podendo ser distribuído ou revendidos a
          terceiros, comprometendo-se a manter sigilo sobre os dados, salvo se
          forem repassados a clientes ou em razão de um contrato onde os dados
          venham a ser usados para ou em função das atividades do{' '}
          <strong>USUÁRIO</strong> ;
        </p>
        <p>
          2.5 - É de inteira e exclusiva responsabilidade do{' '}
          <strong>USUÁRIO</strong> a administração, criação e manutenção de suas
          pesquisas e uso integral da ferramenta. A <strong>AIRSTRIP</strong>{' '}
          não efetuará qualquer configuração e/ou administração da ferramenta em
          nome do <strong>USUÁRIO</strong> , limitando-se apenas a prestar
          suporte <em>on-line</em> ao programa, na forma da cláusula 3.1;
        </p>
        <p>
          2.6 - A <strong>AIRSTRIP</strong> não garante a exatidão ou
          atualização das informações disponíveis para a utilização da
          plataforma, uma vez que não possui qualquer ingerência ou controle
          sobre os dados das redes sociais e Google Analytics das informações
          postadas pelos usuários das aludidas plataformas em que o programa e a
          prestação dos serviços se baseia, uma vez que podem estar
          desatualizadas, incompletas ou incorretas. Não estão incluídas no
          objeto deste contrato e da prestação dos serviços, quaisquer
          garantias, somente a validade técnica e operacional;
        </p>
        <p>
          2.7 - A plataforma, poderá, a partir da primeira utilização, utilizar
          armazenamento local que facilita e aprimora o uso e desempenho do
          programa.A <strong>AIRSTRIP</strong> poderá monitorar a conta da{' '}
          <strong>USUÁRIO</strong> e os relatórios relacionados, visando
          otimizar o uso das aplicações, mantendo esses dados sempre em sigilo,
          e que serão usados para melhoria contínua da ferramenta. O programa
          não armazenará nenhum dado das buscas efetuadas, cabendo a ela, se
          quiser, efetuar a baixa desses dados, e nem armazenará dados sensíveis
          da <strong>USUÁRIO</strong> ;
        </p>
        <p>
          2.8 - É proibido o uso da plataforma para o envio em massa de
          mensagens não solicitadas &quot;SPAM&quot;, para a promoção de
          produtos, serviços ou entidades de natureza comercial ou não, com ou
          sem fins lucrativos, sob pena de rescisão motivada do contrato;
        </p>
        <p>
          2.9 - Os dados usados pela ferramenta são aqueles que se refiram a
          usuários da plataforma prevista na cláusula 1.1, desde que esses
          perfis sejam abertos ao público, e que possibilitem a análise dos
          dados públicos dos usuários. Em hipótese alguma o programa fará
          invasões ou entradas forçadas em perfis fechados ou bloqueados e esses
          dados não poderão ser usados para nenhuma finalidade;
        </p>
        <p>
          2.10 – Todos os dados serão entregues de forma anonimizada,
          estatísticos e impessoais, não sendo possível efetuar a identificação
          dos usuários. Além disso, não será possível, em hipótese alguma, obter
          dados pessoais ou sensíveis dos usuários. Em relação aos
          influenciadores digitais, só serão fornecidos os dados mínimos que
          permitam sua identificação na campanha cadastrada e acompanhada pelo{' '}
          <strong>USUÁRIO</strong> ;
        </p>
        <br />
        <p>
          <strong>3. - DO SUPORTE TÉCNICO:</strong>
        </p>
        <p>
          3.1 - A licença de uso da plataforma inclui suporte técnico básico e
          gratuito pelo prazo de validade deste contrato, que será prestado por
          chat, diretamente na plataforma, em dias úteis e dentro do horário
          comercial. O serviço de suporte técnico é limitado à solução de
          questões relacionadas ao uso dos recursos da plataforma. Em virtude de
          determinações do Poder Público, eventos ou acontecimentos
          extraordinários que impeçam a prestação de serviços de forma
          presencial, o suporte técnico poderá sofrer interrupções ou atrasos,
          sem que isso seja considerado como uma infração ao contrato;
        </p>
        <p>
          3.2 – Fica excluída integralmente do suporte a utilização da
          plataforma através do navegador Internet Explorer;
        </p>
        <p>
          3.3 - Como se trata de um software <em>on-line</em>, nenhum programa
          será instalado nas máquinas do <strong>USUÁRIO</strong> , não havendo
          possibilidade de incompatibilidades de qualquer tipo. O software{' '}
          <em>
            {' '}
            <strong>HYPOLAKE</strong>{' '}
          </em>{' '}
          é compatível com todos os equipamentos e sistemas operacionais;
        </p>
        <p>
          3.4 - A <strong>AIRSTRIP</strong> não será responsável pela criação,
          manutenção ou configuração de buscas ou campanhas publicitárias, ou de
          qualquer espécie de pesquisas com ou sem o uso do programa;
        </p>
        <br />
        <p>
          <strong>4. - DAS MANUTENÇÕES E INTERRUPÇÕES DOS SERVIÇOS:</strong>
        </p>
        <p>
          4.1 - A <strong>AIRSTRIP</strong> se obriga a envidar todos os
          esforços para que a plataforma esteja sempre atualizada, segura, livre
          de erros e falhas que possam acarretar a perda de conteúdo, dados e/ou
          informações durante o período de utilização do{' '}
          <em>
            {' '}
            <strong>HYPOLAKE</strong>{' '}
          </em>
          . Contudo, embora a <strong>AIRSTRIP</strong> adote as medidas que
          dispõe para manutenção dos seus sistemas, poderão ocorrer interrupções
          ou paradas ocasionais, programadas ou não, como ocorre em qualquer
          serviço <em>on-line</em>, situação em que a <strong>AIRSTRIP</strong>{' '}
          não será responsável por qualquer perda ou prejuízo que o{' '}
          <strong>USUÁRIO</strong> possa vir a sofrer em decorrência dos
          referidos eventos. A <strong>AIRSTRIP</strong> se compromete a
          comunicar por escrito as paradas programadas com ao menos 24 horas de
          antecedência;
        </p>
        <p>
          4.2 - Em razão de sua característica <em>on-line</em>, fica entendido
          que caso as redes sociais ou o Google Analytics estejam indisponíveis,
          ou em caso de interrupção nas conexões de internet, não se garante que
          a plataforma esteja acessível em tempo integral, sem que isso possa
          ser entendido como desrespeito ao SLA mínimo previsto na cláusula 4.3.
          Contudo, mesmo que não seja possível acessar os dados, todo conteúdo e
          os dados gerados na ferramenta{' '}
          <em>
            {' '}
            <strong>HYPOLAKE</strong>{' '}
          </em>{' '}
          estarão acessíveis;
        </p>
        <p>
          4.3 - A <strong>AIRSTRIP</strong> garante que o SLA do programa será
          de, no mínimo 90% do tempo acessível e funcional dentro do mês, não
          cumulativo, salvo na ocorrência de caso furtuito ou força maior, com a
          exclusão dos eventos definidos na cláusula 4.5;
        </p>
        <p>
          4.4 - A <strong>AIRSTRIP</strong> poderá realizar atualizações,{' '}
          <em>updates</em>, <em>upgrades</em> ou aprimoramentos da plataforma,
          bem como fixar novas regras de licenciamento, devendo a{' '}
          <strong>USUÁRIO</strong> , antes de sua utilização, lê-las e
          aceitá-las, sendo entendido como aceitas em caso de continuidade do
          uso. Caso não concorde com as novas regras ou as atualizações não
          atendam aos seus interesses, poderá rescindir o contrato sem qualquer
          penalidade;
        </p>
        <p>
          4.5 - No caso de paradas não programadas, a <strong>AIRSTRIP</strong>{' '}
          envidará todos os esforços para restabelecer a operacionalidade do
          programa no prazo de até 48 horas contados do início da inatividade
          e/ou falha, desde que decorrentes do funcionamento da própria
          plataforma e não esteja relacionado a falhas de terceiros, tais como
          provedores de serviço de valor adicionado ou provedores de conteúdo e
          das próprias redes sociais;
        </p>
        <p>
          4.6 - Se a inatividade e/ou falha do programa perdurar por período
          superior a 48 horas, o valor excedido será calculado de forma
          proporcional e serão descontados do preço da licença adquirida no
          próximo mês. Caso o pagamento esteja sendo feito de forma parcelada,
          os valores serão descontados nas parcelas seguintes ao da ocorrência
          do evento. Caso o pagamento tenha sido efetuado à vista, a{' '}
          <strong>AIRSTRIP</strong> reembolsará esses valores na forma de
          créditos excedentes;
        </p>
        <br />
        <p>
          <strong>5. - DO VALOR E FORMA DE PAGAMENTO:</strong>
        </p>
        <p>
          5.1 – Pela prestação dos serviços e uso do programa, o{' '}
          <strong>USUÁRIO</strong> pagará à <strong>AIRSTRIP</strong> o valor
          correspondente ao plano escolhido e que estiver em vigor no momento do
          pagamento, mediante o uso da tabela disponível na URL{' '}
          <a href="http://airfluencers.com/planos">
            http://hypolake.com/planos
          </a>
          . Todos os pagamentos deverão ser efetuados de forma antecipada, a
          cada 30 dias (quando aplicável), mediante o uso de cartão de crédito
          informado quando da contratação, ou outros que venham a ser
          implementados dentro da plataforma. Os valores serão reajustados
          anualmente ou na menor periodicidade prevista em lei, a cada ano de
          vigência deste instrumento, pela variação anual do IGP-M publicado
          pela Fundação Getúlio Vargas (FGV) ou por outro índice que venha a
          substituí-lo;
        </p>
        <p>
          5.2 - A qualquer momento o <strong>USUÁRIO</strong> poderá optar por
          outro plano superior, arcando com a diferença de forma proporcional
          (se houver). A mudança para plano inferior só será possível após
          transcorrido o término do prazo do contrato em vigor. Caso o plano
          escolhido seja extinto, automaticamente haverá a migração do atual
          para aquele que lhe substituir, arcando o <strong>USUÁRIO</strong> com
          as diferenças se houver;
        </p>
        <p>
          5.3 - Ao escolher uma forma de pagamento, o <strong>USUÁRIO</strong>{' '}
          declara estar autorizado a utilizá-la, bem como declara que todas as
          informações fornecidas são verdadeiras e precisas, e autoriza a{' '}
          <strong>AIRSTRIP</strong> a cobrá-lo, e que seja realizada a cobrança
          de todo e qualquer serviço adicional que vier a ser utilizado, de
          acordo com os valores vigentes na data da contratação;
        </p>
        <p>
          5.4 - Em caso de atraso ou inadimplemento das obrigações relativas ao
          pagamento da licença, incidirá multa moratória de 10% sobre o valor
          devido, juros de mora de 1% ao mês e correção monetária pelo IGP-M,
          sempre calculados <em>pro rata die</em>, até posterior pagamento. Caso
          o inadimplemento se dê por mais de 7 dias, o acesso à plataforma e a
          todos os seus recursos será integralmente suspenso e só será
          restabelecido em caso de pagamento de todas as parcelas vencidas. Uma
          vez regularizados os pagamentos e não tendo este contrato sido
          rescindido, obriga-se a <strong>AIRSTRIP</strong> a restabelecer o
          acesso no prazo máximo de 24 horas da identificação do pagamento;
        </p>
        <p>
          5.5 – Mesmo após o cancelamento deste contrato, todos os valores que
          ainda permanecerem em aberto poderão ser cobrados através de todos os
          meios possíveis, inclusive com o envio do cadastro da{' '}
          <strong>AIRSTRIP</strong> aos cadastros de proteção ao crédito;
        </p>
        <br />
        <p>
          <strong>6. - DO SIGILO DAS INFORMAÇÕES:</strong>
        </p>
        <p>
          6.1 - A <strong>AIRSTRIP</strong> poderá acessar e preservar
          informações públicas associadas ao uso da plataforma, incluindo, mas
          não se limitando ao seu conteúdo, perfis, dados de campanhas, dentre
          outros. Sempre que a <strong>AIRSTRIP</strong> for obrigada a atender
          exigências de autoridades, bem como proceder à manutenção dos
          serviços, aplicação do contrato e à proteção de seus interesses e/ou
          direitos ou de outros usuários, esses dados poderão ser
          disponibilizados de acordo com os termos da Lei Geral de Proteção de
          Dados do Brasil;
        </p>
        <p>
          6.2 - As <strong>PARTES</strong> concordam em tratar como sigilosas
          todas as informações que venham a ter acesso durante este contrato ou
          em razão dele, umas das outras, e comprometem-se em não divulgá-las,
          utilizá-las, vendê-las, copiá-las, descompilar, desenvolvê-las,
          aperfeiçoá-las, não fazer engenharia reversa e nem permitir que sejam
          divulgadas por terceiros sem o consentimento prévio e por escrito da{' '}
          <strong>OUTRA PARTE</strong> , seja em seu estado natural ou
          modificadas em qualquer nível, pelo período de 2 anos após o término
          da prestação dos serviços, seja ela qual for, sob pena de ser
          diretamente responsabilizado pela ofensa a essa cláusula, através da
          tomada das medidas judicias cabíveis;
        </p>
        <p>
          6.3 - A <strong>AIRSTRIP</strong> adotará as melhores práticas ao seu
          alcance para proporcionar nível compatível de privacidade e segurança
          de dados, mantendo os seus servidores e sistemas atualizados e
          seguros, não podendo, contudo, assegurar a absoluta e total
          inviolabilidade do sistema em decorrência de condutas que possam ser
          atribuídas a atuações de crackers ou assemelhados, ou ainda em
          decorrência de falhas desconhecidas não divulgadas, ou para as quais a{' '}
          <strong>AIRSTRIP</strong> ainda não tenha desenvolvido adequada
          proteção ou ajuste;
        </p>
        <br />
        <p>
          <strong>7. - DOS DIREITOS AUTORAIS:</strong>
        </p>
        <p>
          7.1 - Todos os softwares, esquemas técnicos, bases de dados,
          metodologias de trabalho e outras ferramentas utilizadas ou que venham
          a ser administradas ou desenvolvidas pela <strong>AIRSTRIP</strong> em
          suas atividades ou em virtude deste contrato, são de sua exclusiva
          propriedade e estão devidamente protegidos nos termos do parágrafo 3º
          do artigo 2º e seguintes da Lei 9.609/98, inciso XII do artigo 7º,
          artigos 18, 19 e seguintes da Lei 9.610/98;
        </p>
        <p>
          7.2 - Todas as invenções, descobertas e desenvolvimentos ou
          aprimoramentos de softwares, hardwares, esquemas técnicos e de
          negócios, produtos de trabalho de qualquer natureza, listas, dados e
          informações veiculadas na plataforma, e qualquer outra informação a
          que tiver acesso ou desenvolver durante ou em virtude da relação de
          prestação de serviços, serão de única e exclusiva propriedade da{' '}
          <strong>AIRSTRIP</strong> , não havendo nenhum direito do{' '}
          <strong>USUÁRIO</strong> de pleitear qualquer lucro, royalties,
          direitos ou patente das invenções ou melhorias, podendo serem usadas
          pelo <strong>USUÁRIO</strong> em suas atividades empresariais;
        </p>
        <p>
          7.3 - É vedado ao <strong>USUÁRIO</strong> modificar as
          características da plataforma, decompilar, desmontar, realizar
          engenharia reversa, modificar os códigos fontes, contornar ou burlar
          medida de proteção tecnológica da plataforma ou a ela relacionadas,
          separar componentes ou funcionalidades, ampliá-los ou alterá-los de
          qualquer forma sem a expressa anuência da <strong>AIRSTRIP</strong> ,
          bem como remover ou alterar as marcas, avisos de copyright ou
          propriedade intelectual e industrial que acompanham a plataforma e sua
          respectiva documentação;
        </p>
        <p>
          7.4 - As marcas e logomarcas relativas ao{' '}
          <em>
            {' '}
            <strong>HYPOLAKE</strong>{' '}
          </em>{' '}
          são de titularidade da <strong>AIRSTRIP</strong> , e seu uso é
          expressamente proibido e deverá ser precedido de autorização por
          escrito. A <strong>AIRSTRIP</strong> poderá fazer menção ao{' '}
          <strong>USUÁRIO</strong> como cliente da plataforma;
        </p>
        <br />
        <p>
          <strong>8. - DO SIGILO DOS DADOS:</strong>
        </p>
        <p>
          8.1 - A ferramenta terá acessoexclusivamente aos dados da navegação do
          usuário dentro da plataforma, sem que isso tenha alguma interferência
          nas transações ou negócios do <strong>USUÁRIO</strong> , ficando,
          desde já esclarecido que a ferramenta não armazena e nem colhe dados
          sigilosos, sensíveis ou pessoais dos usuários do programa{' '}
          <em>
            {' '}
            <strong>HYPOLAKE</strong>{' '}
          </em>{' '}
          ou de usuários das redes sociais, que permitam sua identificação;
        </p>
        <p>
          8.2 - Na forma da lei 13.709/2018, todos os dados obtidos através do
          uso do programa e da prestação de serviços são públicos e de perfis
          públicos das plataformas citadas na cláusula 1.1, mas não poderão ser
          objeto de novo tratamento, e todos os dados serão entregues de forma
          anonimizada pela ferramenta, salvo quanto ao objeto do contrato que
          diz respeito a dados abertos e de conhecimento público, que serão
          tratados com o fim único e exclusivo de identificar os influenciadores
          mais relevantes aos fins colimados pela <strong>USUÁRIO</strong> ;
        </p>
        <p>
          8.3 - As <strong>PARTES</strong> realizarão o tratamento dos dados
          pessoais decorrente do presente contrato unicamente com a finalidade
          de executar suas obrigações contratuais e atingir seus objetivos. Cada{' '}
          <strong>PARTE</strong> será exclusivamente responsável pelas
          finalidades do tratamento para as quais sejam consideradas como
          controladores independentes, e de modo conjunto em relação às
          finalidades do tratamento para as quais eventualmente sejam
          controladores conjuntos;
        </p>
        <p>
          8.4 - O programa e os serviços não identificarão, recolherão e nem
          tratarão qualquer tipo de dado considerado sensível dos usuários das
          redes sociais, mas apenas e tão somente dados tornados públicos pelo
          titular, na forma do parágrafo 4 do artigo 7 da Lei Geral de Proteção
          de Dados do Brasil, e todos os dados pessoais serão tratados segundo
          as diretrizes do artigo 6 da mesma Lei citada, e não poderão ser
          transferidos para o exterior salvo com autorização expressa da{' '}
          <strong>AIRSTRIP</strong>. Os únicos dados tratados serão o nome de
          usuário e interações com o influenciador digital;
        </p>
        <p>
          8.5 – Através do uso da plataforma, não será possível a identificação
          precisa e segura de qualquer usuário das redes sociais que interajam
          com os influenciadores digitais. A ferramenta não recolherá qualquer
          tipo de dado que permita esse tipo de identificação, dentro dos moldes
          preceituados pela RGDP da União Europeia, quando aplicável;
        </p>
        <p>
          8.6 - A utilização dos dados coletados ou de outra forma tratados com
          base neste contrato para finalidades secundárias, derivadas ou não
          relacionadas ao seu objeto, independentemente de culpa ou dolo,
          levarão à assunção de responsabilidade integral pela controladora
          daquele novo tratamento específico, por toda a legalidade e
          legitimidade deste, inclusive por eventuais danos ou sanções
          decorrentes de violação legal e desvio de finalidade no tratamento dos
          dados, excluindo integralmente a outra parte que não anuiu com esse
          novo tratamento;
        </p>
        <p>
          <strong>8.7 -</strong>{' '}
          <strong>
            É TOTALMENTE VEDADO O TRATAMENTO POSTERIOR DOS DADOS PELA USUÁRIO,
            COM O FIM DE REVERTER A ANONIMIZAÇÃO, IDENTIFICAR E RECOLHER
            QUALQUER OUTRO DADO DOS USUÁRIOS DAS REDES SOCIAIS, SOB PENA DE
            ENCERRAMENTO IMEDIATO DO CONTRATO POR JUSTA CAUSA E SEM QUALQUER
            DIREITO POSTERIOR DA USUÁRIO. CASO A USUÁRIO VENHA A EFETUAR ESSA
            PRÁTICA, SERÁ A ÚNICA RESPONSÁVEL PELOS EVENTUAIS PREJUÍZOS QUE VIER
            A CAUSAR**
          </strong>{' '}
          ;**
        </p>
        <p>
          8.8 – Em caso de desrespeito aos termos de privacidade deste contrato,
          a <strong>USUÁRIO</strong> poderá ser acionada judicialmente para que
          seja obrigada a destruir todos os dados obtidos, bem como fazer cessar
          o uso desses dados e ressarcir a <strong>AIRSTRIP</strong> de todos os
          prejuízos que venha a causar;
        </p>
        <p>
          8.9 - Em caso de acesso não autorizados ou situações acidentais ou
          ilícitas de perda, alteração, divulgação ou qualquer outro tipo de
          incidente que possibilite a comunicação de dados pessoais ou sensíveis
          dos usuários ou dos influenciadores digitais e que seja decorrente do
          tratamento conduzido pela outra Parte sob este contrato,
          independentemente do motivo que o tenha ocasionado, deverá a Parte
          responsável comunicar a Parte inocente, por escrito, em até 24 horas
          da ciência do incidente, contendo, no mínimo, as seguintes
          informações: (i) data e hora do incidente; (ii) data e hora da ciência
          pela Parte; (iii) relação dos tipos de dados afetados pelo incidente;
          (iv) número certo ou estimado de titulares afetados; (v) dados de
          contato do encarregado de proteção de dados e/ou outra pessoa junto à
          qual seja possível obter maiores informações sobre o ocorrido; (vi)
          descrição das possíveis consequências do incidente; e (vii) indicação
          de medidas que estiverem sendo tomadas para reparar o dano e evitar
          novos incidentes. Caso a Parte responsável não disponha de todas as
          informações ora elencadas no momento de envio da comunicação, deverá
          enviá-las gradualmente, de forma a garantir a maior celeridade
          possível;
        </p>
        <br />
        <p>
          <strong>9. - DO PRAZO E RESCISÃO:</strong>
        </p>
        <p>
          9.1 – A permissão para uso da plataforma e a prestação dos serviços
          entra em vigor na data de aceite deste contrato, e permanecerá em
          vigor enquanto estiverem sendo realizados os pagamentos pelo{' '}
          <strong>USUÁRIO</strong> , sendo renovada automaticamente.
          Considera-se como renovado tacitamente desde que os pagamentos sejam
          realizados, independentemente de uso ou não da plataforma;
        </p>
        <p>
          9.2 - Qualquer das partes poderá rescindir este contrato,
          imediatamente, por inadimplemento da outra parte que não tenha sido
          sanado no prazo de 5 dias após comunicação formal, por escrito, ou
          ainda, em caso de falência, recuperação judicial ou extrajudicial ou,
          ainda, insolvência nos pagamentos;
        </p>
        <p>
          9.4 - A <strong>AIRSTRIP</strong> poderá, a qualquer momento,
          suspender o acesso ao software caso seja identificado abuso no uso,
          que poderá se dar de diversas formas, notadamente através de
          identificação de endereço IP incompatível com a localização geográfica
          da <strong>USUÁRIO</strong> , mediante análise técnica. Neste caso, a{' '}
          <strong>AIRSTRIP</strong> poderá, antes de suspender o acesso,
          notificar a <strong>USUÁRIO</strong> sobre os fatos ocorridos e caso o
          desrespeito prossiga, o login será suspenso por 3 dias corridos. Se
          mesmo assim o abuso continuar, o contrato será rescindido
          justificadamente sem direito ao ressarcimento de qualquer valor
          quitado;
        </p>
        <p>
          9.5 – Em caso de rescisão por conta de infrações decorrentes do uso
          desautorizado de dados obtidos com a ferramenta, a{' '}
          <strong>USUÁRIO</strong> poderá ser chamada ao processo ou denunciada
          a lide (se aplicável), e concorda em ressarcir a{' '}
          <strong>AIRSTRIP</strong> de todos os valores e prejuízos ocasionados
          por sua conduta ou de terceiros a ela ligados;
        </p>
        <br />
        <p>
          <strong>10. – DAS COMUNICAÇÕES:</strong>
        </p>
        <p>
          10.1 - Todas as comunicações entre as partes deverão ser enviadas e
          realizadas através dos seguintes endereços eletrônicos e para as
          seguintes pessoas:
        </p>
        <p>
          Para a <strong>AIRSTRIP</strong> : contato@hypolake.com
        </p>
        <p>
          Para o <strong>USUÁRIO</strong> : através dos dados fornecidos no
          preenchimento do formulário on-line. Caso haja alteração dos dados de
          contato e essa alteração não seja comunicada, serão válidas todas as
          comunicações enviadas;
        </p>

        <br />
        <p>
          <strong>11. - DAS DISPOSIÇÕES GERAIS:</strong>
        </p>
        <p>
          11.1 - Toda e qualquer alteração deste contrato deverá ser realizada
          por escrito, mediante envio de comunicação para a outra parte, sendo
          suficiente troca de e-mails com aviso de recebimento;
        </p>
        <p>
          11.2 - A <strong>AIRSTRIP</strong> poderá ceder, a qualquer momento,
          no todo ou em parte, os direitos e obrigações assumidas no presente
          instrumento, devendo o cessionário cumprir e respeitar os termos
          previstos neste contrato até a data de seu encerramento;
        </p>
        <p>
          11.3 - A <strong>AIRSTRIP</strong> não será responsável, nem mesmo em
          caráter subsidiário, por quaisquer danos diretos ou indiretos oriundos
          de informações desatualizadas, equivocadas e/ou incompletas colhidas
          pelo <strong>USUÁRIO</strong> por intermédio da plataforma, desde que
          oriundas das bases de dados acessadas e/ou dos sites de relacionamento
          ou mídias sociais cobertas, ou mesmo em decorrência do uso errôneo ou
          parcial do programa;
        </p>
        <p>
          11.4 - A <strong>AIRSTRIP</strong> obriga-se a manter o{' '}
          <strong>USUÁRIO</strong> isenta de toda e qualquer eventual reclamação
          de terceiros que aleguem possuir direitos sob o software e/ou eventual
          violação de qualquer propriedade intelectual praticada pela{' '}
          <strong>AIRSTRIP</strong> , assumindo todos os prejuízos e a defesa
          integral, substituindo-a no polo passivo e arcando com todos os
          pagamentos devidos;
        </p>
        <p>
          11.5 - O não exercício de qualquer direito assegurado por este
          instrumento não poderá ser interpretado como renúncia ao mesmo, não
          podendo os precedentes serem invocados como novação tácita do
          Contrato, cujas cláusulas permanecerão sempre em pleno vigor,
          reconhecidas ainda às partes interessadas a possibilidade de, a
          qualquer tempo, tornar efetivo o direito até então não exercido;
        </p>
        <p>
          11.6 - As partes deste contrato são independentes e nada aqui citado
          deverá ser interpretado como vínculo empregatício, joint-venture,
          sociedade de fato ou de direito ou consórcio entre as partes. Nenhuma
          das partes tem qualquer direito, poder ou autoridade de entrar em
          qualquer acordo para ou por conta da outra parte, ou incorrer em
          qualquer obrigação ou responsabilidade em nome da outra, sob pena de
          invalidade e responder isoladamente;
        </p>
        <p>
          11.7 - Nenhuma das <strong>PARTES</strong> responde pelos insucessos
          comerciais da outra, nem por reclamações de terceiros, exceto nos
          casos em que for comprovada a ação ou omissão deliberada de uma{' '}
          <strong>PARTE</strong> para prejudicar a outra;
        </p>
        <p>
          11.8 - AS <strong>PARTES</strong> se comprometem a cumprir e a fazer
          com que todas as pessoas envolvidas neste contrato cumpram com todas
          as leis, normas e regulamentos aplicáveis, incluindo, sem limitação,
          às normas sanitárias, ambientais, de saúde e segurança, trabalhistas,
          previdenciárias e fiscais, que em hipótese alguma empregaram ou
          empregarão qualquer forma de trabalho infantil ou trabalho em
          condições análogas à de escravo;
        </p>
        <p>
          11.9 – O <strong>USUÁRIO</strong> declara que leu este contrato e
          também as Políticas de Uso da ferramenta que estão na URL - , e também
          está de acordo com todas as suas disposições;
        </p>
        <p>
          11.10 - Não chegando a um acordo, as partes utilizarão de todos os
          métodos de solução consensual de conflitos, e em caso de necessidade,
          apresentarão o caso a uma das Câmara de Arbitragem da cidade de São
          Paulo, nos termos da Lei 9.307/96, arcando o perdedor com todos os
          custos e honorários decorrentes do procedimento;
        </p>
        <p>
          11.11 - O presente contrato obriga as partes, seus herdeiros e
          sucessores a qualquer título;
        </p>
        <p>
          11.12 - Caso uma das disposições do presente contrato seja declarada
          nula, todas as outras não afetadas permanecerão válidas;
        </p>
        <p>
          11.13 - As partes elegem o foro da Capital do Estado de São Paulo, com
          exclusão de qualquer outro, por mais privilegiado que seja, para
          dirimir qualquer controvérsia oriunda do presente Termo.
        </p>
        <p>
          E, por estarem justas e contratadas, a <strong>USUÁRIO</strong>{' '}
          declara que leu este contrato e aceitou seus termos de forma expressa
          ao iniciar o uso do programa e efetuar o pagamento das parcelas
          decorrentes do plano contratado.
        </p>

        <br />
        <br />
        <p>São Paulo, 31 de março de 2022. (data da última atualização).</p>
      </div>
    </>
  )
}

export default TermsOfUsePT
