import { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import BackgroundImage from '../../../assets/images/bg-gradient.png'
import gtA from '../../../assets/icons/gt-a.svg'
import gtB from '../../../assets/icons/gt-b.svg'
import './index.scss'
import { extractParams } from '../../../../utils/payload'
import { connectDetails } from './ContentServices'

function Content({ leftSideChildren, rightSideChildren }) {
  const [connectData, setConnectData] = useState()
  const [clientName, setClientName] = useState()
  const [campaignName, setCampaignName] = useState()

  useEffect(() => {
    setConnectData(extractParams())
  }, [])

  useEffect(() => {
    if (connectData) {
      connectDetails(connectData.air_id, connectData.client_id).then((res) => {
        const {
          campaign_name: fetchedCampaignName,
          client_name: fetchedClientName,
        } = res.data

        setCampaignName(fetchedCampaignName)
        setClientName(fetchedClientName)
      })
    }
  }, [connectData])

  return (
    <div id="content" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      <div className="container">
        <div className="left-area">
          <span className="requester-name">{clientName}</span>
          {clientName && (
            <span className="request-separator">
              <img src={gtA} alt="gt" />
              <img src={gtB} alt="gt" />
            </span>
          )}
          <span className="requested-name">{campaignName}</span>
          <div>{leftSideChildren}</div>
        </div>
        <div className="right-area">
          <div>{rightSideChildren}</div>
        </div>
      </div>
    </div>
  )
}

Content.propTypes = {
  leftSideChildren: propTypes.node,
  rightSideChildren: propTypes.node,
}

Content.defaultProps = {
  leftSideChildren: <></>,
  rightSideChildren: <></>,
}

export default Content
