import { GoogleOAuthProvider } from '@react-oauth/google'
import { useTranslation } from 'react-i18next'
import GroupIcon from '../../../../common/assets/icons/group.svg'
import SearchIcon from '../../../../common/assets/icons/search.svg'
import TargetIcon from '../../../../common/assets/icons/target.svg'
import ArrowIcon from '../../../../common/assets/images/arrow.svg'
import Card from '../../../../common/components/Card/Card'
import './index.scss'
import GoogleConnect from './components/GoogleConnect/GoogleConnect'
import Content from '../../../../common/components/v2/Content/Content'

function Arrow() {
  return <img src={ArrowIcon} alt="arrow" />
}

function Connect() {
  const { t } = useTranslation()

  return (
    <Content
      leftSideChildren={
        <>
          <h1>{t('google_monitor_description')}</h1>
          <p>{t('google_connect_description')}</p>
          <div className="workflow">
            <Card
              icon={GroupIcon}
              text={t('google_arrow_connect')}
              iconAlt="icon"
            />
            <Arrow />
            <Card
              icon={SearchIcon}
              text={t('google_arrow_track')}
              iconAlt="icon"
            />
            <Arrow />
            <Card
              icon={TargetIcon}
              text={t('google_arrow_optimize')}
              iconAlt="icon"
            />
          </div>
        </>
      }
      rightSideChildren={
        <>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          >
            <GoogleConnect />
          </GoogleOAuthProvider>
        </>
      }
    />
  )
}

export default Connect
