import React, { useEffect, useState } from 'react'
import FacebookLogin from 'react-facebook-login'
import propTypes from 'prop-types'
import InstagramIcon from '../../common/assets/icons/iframe-Instagram.svg'
import TwitterIcon from '../../common/assets/icons/iframe-twitter.svg'
import Modal from '../../common/components/Modal'
import { connectInstagramProfile } from '../Home/HomeServices'
import { connectTwitter, getTwitterConnectUrl } from './IframeServices'
import './index.scss'

const CLIENT_ID = 'site_air'

function Iframe() {
  const [modalState, setModalState] = useState({
    show: false,
    text: null,
    title: null,
    airId: null,
  })

  const connectMessageCallback = (success, connectData) => {
    const event = new CustomEvent('connectEvent', {
      success,
      connectData,
    })
    window.parent.document.dispatchEvent(event)
  }

  const facebookCallback = async (response) => {
    if (response.status !== 'unknown') {
      setModalState({
        ...modalState,
        text: 'Você está quase lá! Estamos validando sua conta...',
      })

      connectInstagramProfile(CLIENT_ID, response.accessToken)
        .then((response2) => {
          setModalState({
            ...modalState,
            text: 'Sucesso! Você já está aparecendo para as grandes marcas!',
          })
          connectMessageCallback(true, response2.data)
        })
        .catch((error) => {
          connectMessageCallback(false, {})
          setModalState({
            ...modalState,
            text: `Erro! Entre em contato com nosso suporte e forneça a seguinte mensgame: ${error.response.data.message}`,
          })
        })
    } else {
      setModalState({
        ...modalState,
        text: 'Ei! Você já fechou o Popup antes de terminar o processo. Não perca as MELHORES CAMPANHAS, tente novamete.',
      })
    }
  }

  const twitterCallBack = (urlParams) => {
    setModalState({
      ...modalState,
      text: 'Aguarde! Estamos validando seu conecte...',
    })

    connectTwitter(
      modalState.airId,
      CLIENT_ID,
      urlParams.get('oauth_token'),
      urlParams.get('oauth_verifier')
    )
      .then(() => {
        setModalState({
          ...modalState,
          text: 'Sucesso! Você já está aparecendo para as grandes marcas!',
        })
      })
      .catch((error) =>
        setModalState({
          ...modalState,
          text: `Erro! Entre em contato com nosso suporte e forneça a seguinte mensgame: ${error.response.data.message}`,
        })
      )
  }

  useEffect(() => {
    window.twitterCallback = twitterCallBack
  }, [modalState])

  const TwitterModalText = ({ urlConnect }) => {
    const openModal = () =>
      window.open(
        urlConnect,
        '_blank',
        'location=yes,height=570,width=600,scrollbars=yes,status=yes'
      )

    return (
      <>
        Pronto! Podemos continuar,{' '}
        <span
          role="button"
          tabIndex="-1"
          onKeyDown={() => {}}
          onClick={openModal}
          style={{ color: 'rgb(29, 155, 240)', cursor: 'pointer' }}
        >
          CLIQUE AQUI.
        </span>
      </>
    )
  }
  TwitterModalText.defaultProps = {
    urlConnect: '',
  }

  TwitterModalText.propTypes = {
    urlConnect: propTypes.string,
  }

  return (
    <>
      <Modal
        showModal={modalState.show}
        title={<strong>{modalState.title}</strong>}
        onClose={() => setModalState({ show: false })}
      >
        {[modalState.text]}
      </Modal>

      <div className="air-iframe">
        <div className="connect-button">
          <div className="icon-button">
            <img className="instagram" src={InstagramIcon} alt="" />
          </div>
          <div className="action-button">
            <FacebookLogin
              onClick={() => {
                setModalState({
                  show: true,
                  title: 'Conectar com o Instagram',
                  text: 'Aguardando Meta....',
                })
              }}
              appId="1009050182802521"
              textButton="Conectar com o Instagram"
              callback={facebookCallback}
              disableMobileRedirect
              scope="pages_read_engagement, instagram_manage_insights, instagram_basic, pages_show_list"
            />
          </div>
        </div>

        <div className="connect-button">
          <div className="icon-button">
            <img className="twitter" src={TwitterIcon} alt="" />
          </div>
          <div className="action-button">
            <div
              role="button"
              tabIndex={-1}
              onKeyDown={() => {}}
              onClick={() => {
                setModalState({
                  show: true,
                  title: 'Conecte com o Twitter',
                  text: 'Um minuto! Estamos preparando para você se conectar...',
                })
                getTwitterConnectUrl(CLIENT_ID).then((response) => {
                  setModalState({
                    title: 'Conectar com o Twitter',
                    show: true,
                    airId: response.data.air_id,
                    text: (
                      <TwitterModalText
                        key="0"
                        urlConnect={response.data.url}
                      />
                    ),
                  })
                })
              }}
              style={{ cursor: 'pointer' }}
            >
              Conectar com o Twitter
            </div>
          </div>
        </div>

        <div className="divider" />
        <div className="disclaimer">
          *Não teremos acesso à dados como: Login; Senha e outras informações
          pessoais.
        </div>
      </div>
    </>
  )
}

export default Iframe
