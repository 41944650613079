import { useTranslation } from 'react-i18next'
import './index.scss'
import { getLink } from '../../../../../../languages/i18n'

function Links() {
  const { t } = useTranslation()

  return (
    <>
      <div className="footer-links">
        <ul className="links">
          <li>
            <a href={getLink('product')} rel="noreferrer" target="_blank">
              {t('footer_product')}
            </a>
          </li>
          <li>
            <a href={getLink('resources')} rel="noreferrer" target="_blank">
              {t('footer_resources')}
            </a>
          </li>
          <li>
            <a href={getLink('plan')} rel="noreferrer" target="_blank">
              {t('footer_plan')}
            </a>
          </li>
          <li>
            <a href={getLink('contact')} rel="noreferrer" target="_blank">
              {t('footer_contact')}
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-privacy">
        <ul className="links">
          <li>
            <a href={getLink('terms_of_use')} rel="noreferrer" target="_blank">
              {t('terms_of_use')}
            </a>
          </li>
          <li>
            <a href={getLink('privacy')} rel="noreferrer" target="_blank">
              {t('privacy_policy')}
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Links
