import Connect from './components/Connect/Connect'
import Footer from '../../common/components/v2/Footer/Footer'
import Header from '../../common/components/v2/Header/Header'
import './index.scss'

function Google() {
  return (
    <div id="google-connect">
      <Header />
      <Connect />
      <Footer />
    </div>
  )
}

export default Google
