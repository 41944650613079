export function extractParams() {
  const { hash } = window.location
  const paramsIndex = hash.indexOf('?')
  const hashPage =
    paramsIndex > -1 ? hash.slice(hash.indexOf('#'), paramsIndex) : hash

  const urlParams = new URLSearchParams(
    window.location.hash.replace(hashPage, '')
  )

  if (urlParams.get('payload') === null) return '{}'
  const source = urlParams.get('source') || 'direct'

  const data = Buffer.from(urlParams.get('payload'), 'base64')
  let jsonData = {}
  try {
    const payload = data.toString()
    jsonData = JSON.parse(payload)
  } catch {
    jsonData = {}
  }

  jsonData.source = source
  return jsonData
}

export default {}
