import api from '../../service'

export const connectInstagram = (airId, clientId, accessToken, source) =>
  api.post('/external/instagram/connect', {
    air_id: airId,
    client_id: clientId,
    access_token: accessToken,
    source,
  })

export const connectInstagramProfile = (clientId, accessToken, source) =>
  api.post('/external/instagram/connect/profile', {
    client_id: clientId,
    access_token: accessToken,
    source,
  })

export const connectTikTok = (airId, tiktokCode, clientID, source) =>
  api.post('/external/tiktok/connect', {
    air_id: airId,
    code: tiktokCode,
    client_id: clientID,
    source,
  })

export const getTwitterConnectUrl = () => api.get('/external/twitter/auth_url')
export const connectTwitter = (airId, clientId, oauthToken, oauthVerifier) =>
  api.post('/external/twitter/connect', {
    air_id: airId,
    client_id: clientId,
    oauth_token: oauthToken,
    oauth_verifier: oauthVerifier,
  })
