import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGoogleLogin } from '@react-oauth/google'
import LockIcon from '../../../../../../common/assets/icons/lock.svg'
import GoogleLogo from '../../../../../../common/assets/images/google.svg'
import './index.scss'
import { connectGoogle } from '../../../../GoogleServices'
import Modal from '../../../../../../common/components/Modal'
import { extractParams } from '../../../../../../utils/payload'
import { getLink } from '../../../../../../languages/i18n'

const requiredPermissions = [
  'email',
  'profile',
  'https://www.googleapis.com/auth/analytics.readonly',
  'openid',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
]

function GoogleConnect() {
  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [statusConnect, setStatusConnect] = useState()
  const [connectData, setConnectData] = useState()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setConnectData(extractParams())
  }, [])

  const checkScopePermissions = (scope) => {
    let isValid = true

    if (scope) {
      requiredPermissions.forEach((item) => {
        if (!scope.includes(item) && isValid) {
          isValid = false
        }
      })
    }

    return isValid
  }
  const responseGoogle = async (response) => {
    const { scope } = response
    const isScopeValid = checkScopePermissions(scope)
    if (isScopeValid) {
      if (response.code) {
        connectGoogle(
          connectData.air_id,
          connectData.client_id,
          response.code,
          connectData.source
        )
          .then(() => {
            setStatusConnect(t('connect_success'))
          })
          .catch((e) => {
            if (e.response.status === 403) {
              setShowErrorModal(true)
            }

            setStatusConnect(
              e.response.data.message
                ? `Error: ${t(e.response.data.message)}`
                : `Error: ${t('connect_error')}`
            )
          })
      }
      return
    }

    setStatusConnect('connect_accept_all_permissions')
  }
  const login = useGoogleLogin({
    flow: 'auth-code',
    prompt: 'consent',
    responseType: 'code',
    approvalPrompt: 'force',
    cookiePolicy: 'single_host_origin',
    onSuccess: responseGoogle,
    onError: responseGoogle,
    onNonOAuthError: () => {
      console.log('Popup close')
      setLoading(false)
      setError(false)
      setStatusConnect(null)
    },
    scope:
      'https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.profile',
  })

  return (
    <div className="google-connect">
      <div className="google-connect-title">
        <img src={LockIcon} alt="lock" />
        <h1>{t('google_connect_with')}</h1>
        <div>{t('google_follow')}</div>
      </div>
      <div className="google-connect-button">
        <div className="div-button-go">
          <button
            type="button"
            className="google-btn"
            onClick={() => {
              setStatusConnect(t('connect_waiting'))
              login()
            }}
          >
            <div className="google-icon-wrapper">
              <img className="google-icon" src={GoogleLogo} alt="google" />
            </div>
            <p className="btn-text">{t('connect_google_sign_in')}</p>
          </button>
        </div>
      </div>
      <p className="google-connect-status">{statusConnect}</p>
      <div className="google-connect-privacy">
        <div>
          {t('connect_box_verify_the')}{' '}
          <a href={getLink('terms_of_use')} target="_blank" rel="noreferrer">
            <b>{t('terms_of_use')}</b>
          </a>{' '}
          {t('connect_box_and_the')}{' '}
          <a href={getLink('privacy')} target="_blank" rel="noreferrer">
            <b>{t('privacy_policy')}</b>
          </a>{' '}
          {t('connect_box_of_hypolake')}
        </div>
      </div>
      {error}
      {loading ? <div>{t('loading')}...</div> : null}
      <Modal
        showModal={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      >
        <div className="modal-body">
          <h2>{t('connect_google_cant_reach')}</h2>
          {t('connect_google_plase_verify')} <br />
          <br />
          {t('connect_google_create_account')}{' '}
          <a
            href="https://analytics.google.com"
            rel="noreferrer"
            target="_blank"
          >
            https://analytics.google.com
          </a>{' '}
          {t('connect_google_and_try_again')}
        </div>
      </Modal>
    </div>
  )
}

export default GoogleConnect
