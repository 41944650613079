import PropTypes from 'prop-types'
import './index.scss'

function Card({ icon, text, iconWidth, iconAlt }) {
  return (
    <div className="card">
      <div className="icon">
        <img src={icon} alt={iconAlt} width={iconWidth} />
      </div>
      <div className="text">{text}</div>
    </div>
  )
}

Card.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  iconWidth: PropTypes.number,
  iconAlt: PropTypes.string,
}

Card.defaultProps = {
  iconWidth: 40,
  iconAlt: 'icon',
}

export default Card
