const en = {
  protection: 'Protection',
  data: 'Data',
  connect: 'Connect',
  google_connect_with: 'Connect with Google Analytics',
  google_connect_description:
    'Connect with Google Analytics to follow your influencer conversions easily and safely.',
  google_monitor_description: 'Track results of your campaign',
  google_arrow_connect: 'Connect',
  google_arrow_track: 'Track conversions',
  google_arrow_optimize: 'Optimize results',
  google_follow: 'Follow with details your conversions',
  mobile_desktop_mode_important: 'Important',
  mobile_desktop_mode_back: 'Back',
  mobile_desktop_mode: 'Desktop Mode',
  mobile_desktop_for_computers: 'For Computers',
  mobile_desktop_site: 'Desktop site',
  mobile_desktop_request_desktop_site: 'Request Desktop Site',
  mobile_desktop_mode_instructions_to: 'To',
  mobile_desktop_mode_instructions_or: 'or',
  mobile_desktop_mode_instructions:
    'with Hypolake you have to change your mobile device\'s browser to "Desktop Mode".',
  mobile_desktop_mode_cant_find:
    'If you are not finding one of these functions we recommend downloading one of the following browsers:',
  mobile_desktop_mode_instructions_menu:
    'with Hypolake with success, access the top corner menu represented by 3 dots, select the option',
  mobile_desktop_mode_instructions_menu_safari:
    'with Hypolake with success, access the top corner menu represented by two "A"s (aA), select the option',
  mobile_desktop_mode_instructions_try_again: 'and try to connect again',
  footer_brands_heading: 'Brands that use Hypolake',
  home_left_side_heading1: 'No more prints!',
  home_left_side_subheader:
    "With connect you don't need to send prints anymore. The brand can access your numbers of:",
  home_reached_accounts: 'Reached accounts',
  home_views: 'Views',
  home_interactions: 'Interactions',
  home_interactions_previous_next_touch: 'Next and previous touches',
  home_interactions_left_post: 'Post exiting',
  connect_box_select_network: 'Connect with your social network',
  connect_box_verify_the: 'Verify the',
  connect_box_and_the: 'and the',
  connect_box_of_hypolake: 'of Hypolake',
  connect_button_tiktok: 'Connect with TikTok',
  connect_button_instagram: 'Connect with Instagram',
  connect_button_twitter: 'Connect with Twitter',
  connect_canceled: 'Connect canceled',
  connect_waiting: 'Waiting connect...',
  connect_validating_authorization: 'Wait, validating authorization...',
  connect_success: 'Account successfully connected!',
  connect_error: "We couldn't connect your account. Try again.",
  connect_closed_popup:
    'Hey! You closed the popup before finishing your connect. Try again..',
  error: 'error',
  footer_product: 'Product',
  footer_resources: 'Resources',
  footer_plan: 'Plan',
  footer_contact: 'Contact',
  terms_of_use: 'Terms of use',
  privacy_policy: 'Privacy policy',
  footer_rights_reserved: 'All rights reserved',
  visit_website: 'Visit website',
  invalid_link: 'Invalid URL, please check the connect link and try again',
  connect_accept_all_permissions: 'You need to accept all permissions.',
  loading: 'Loading',
  connect_google_cant_reach: "We can't reach your google analytics account.",
  connect_google_plase_verify:
    'Please verify if you connected the right google account.',
  connect_google_create_account:
    'If the account is correct, please create an account accessing',
  connect_google_and_try_again: 'and try again.',
  connect_google_sign_in: 'Sign in with google',
}

export default en
