import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConnectButton from './ConnectButton'
import TiktokIcon from '../../../../../../common/assets/icons/tiktok-transparent.svg'
import ConnectContext from '../../../../../../contexts/ConnectContext/ConnectContext'
import { connectTikTok } from '../../../../HomeServices'

function TikTokConnectButton() {
  const { t } = useTranslation()
  const { setStatusConnect, connectData, network } = useContext(ConnectContext)
  const [callbackReturned, setCallbackReturned] = useState(false)

  function openTiktokUrl() {
    let connectURL = process.env.REACT_APP_TIKTOK_CONNECT_URL
    connectURL = `${connectURL}&air_id=${connectData.air_id}&client_id=${connectData.client_id}`

    const popup = window.open(
      connectURL,
      '_blank',
      'location=yes,height=570,width=600,scrollbars=yes,status=yes'
    )

    if (popup) {
      const timer = setInterval(() => {
        if (popup.closed && !callbackReturned) {
          clearInterval(timer)
          setStatusConnect(t('connect_canceled'))
        }
      }, 1000)
    }

    setStatusConnect(t('connect_waiting'))
  }

  const tikTokCallBack = (urlParams) => {
    setCallbackReturned(true)
    if (urlParams.get('error')) {
      setStatusConnect(`Error: ${urlParams.get('error')}`)
      return null
    }

    setStatusConnect(t('connect_validating_authorization'))

    connectTikTok(
      connectData.air_id,
      urlParams.get('code'),
      connectData.client_id,
      connectData.source
    )
      .then(() => {
        setStatusConnect(t('connect_success'))
      })
      .catch((error) => {
        setStatusConnect(`{${t('error')}: ${error.response.data.message}`)
      })

    return null
  }

  useEffect(() => {
    window.tikTokCallBack = tikTokCallBack
  }, [tikTokCallBack])

  return (
    <ConnectButton
      icon={TiktokIcon}
      text={t('connect_button_tiktok')}
      backgroundStyles={{ backgroundColor: '#000', color: '#fff' }}
      disabled={network !== 'tiktok'}
      onClick={() => {
        openTiktokUrl()
      }}
    />
  )
}

export default TikTokConnectButton
