import api from '../../service'

export const connectGoogle = (airId, clientId, code) =>
  api.post('/external/google/connect', {
    air_id: airId,
    client_id: clientId,
    code,
  })

export default {}
