import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Logo from '../../../assets/images/logo.svg'
import Links from './components/Links/Links'
import InstagramIcon from '../../../assets/icons/instagram-white.svg'
import LinkedinIcon from '../../../assets/icons/linkedin-white.svg'

import NuLogo from '../../../assets/images/brands/nu.png'
import SalveLogo from '../../../assets/images/brands/sallve.png'
import GloboLogo from '../../../assets/images/brands/globo.png'
import LorealLogo from '../../../assets/images/brands/loreal.png'
import UnileverLogo from '../../../assets/images/brands/unilever.png'
import TelefonicaLogo from '../../../assets/images/brands/telefonica.png'
import TelecineLogo from '../../../assets/images/brands/telecine.png'
import NestleLogo from '../../../assets/images/brands/nestle.png'
import AzulLogo from '../../../assets/images/brands/azul.png'
import KindleLogo from '../../../assets/images/brands/kindle.png'

import './index.scss'

function Footer({ showBrands }) {
  const { t } = useTranslation()
  const brands = [
    { alt: 'Nu', src: NuLogo },
    { alt: 'Sallve', src: SalveLogo },
    { alt: 'Globo', src: GloboLogo },
    { alt: 'Loreal', src: LorealLogo },
    { alt: 'Unilever', src: UnileverLogo },
    { alt: 'Telefonica', src: TelefonicaLogo },
    { alt: 'Telecine', src: TelecineLogo },
    { alt: 'Nestle', src: NestleLogo },
    { alt: 'Azul', src: AzulLogo },
    { alt: 'Kindle', src: KindleLogo },
  ]
  return (
    <footer id="footer">
      {showBrands && (
        <div className="brands-content">
          <h1>{t('footer_brands_heading')}</h1>
          <div className="brands-images">
            {brands.map((brand) => (
              <img alt={brand.alt} src={brand.src} key={`${brand.alt}`} />
            ))}
          </div>
        </div>
      )}
      <div className="footer-content">
        <div className="left-area">
          <img src={Logo} alt="logo" width={197} />
          <Links />
        </div>
        <div className="right-area">
          <div className="social-networks">
            <div className="social-network-icon">
              <a
                href="https://www.instagram.com/hypo.lake/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstagramIcon} alt="instagram" />
              </a>
            </div>
            <div className="social-network-icon">
              <a
                href="https://www.linkedin.com/company/hypolake/about/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedinIcon} alt="linkedin" />
              </a>
            </div>
          </div>
          <div className="rights">
            <span>
              Hypolake © {new Date().getFullYear()} -{' '}
              {t('footer_rights_reserved')}.
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  showBrands: propTypes.bool,
}

Footer.defaultProps = {
  showBrands: true,
}
export default Footer
