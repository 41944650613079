import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import TikTokConnectButton from './components/ConnectButton/TikTokConnectButton'
import ConnectContext from '../../../../contexts/ConnectContext/ConnectContext'
import InstagramConnectButton from './components/ConnectButton/InstagramConnectButton'
import './index.scss'
import { getLink } from '../../../../languages/i18n'

function ConnectBox() {
  const { statusConnect, network, connectDataLoading } =
    useContext(ConnectContext)
  const { t } = useTranslation()
  return (
    <>
      <div id="connect-box">
        {network && <p>{t('connect_box_select_network')}</p>}
        {network === 'tiktok' && <TikTokConnectButton />}
        {network === 'instagram' && <InstagramConnectButton />}

        {!connectDataLoading && !network && (
          <div className="connect-box-invalid-link">
            <p>{t('invalid_link')}</p>
          </div>
        )}

        {/* <TwitterConnectButton /> */}
        <div className="connect-box-privacy">
          <p>
            {t('connect_box_verify_the')}{' '}
            <a href={getLink('terms_of_use')} target="_blank" rel="noreferrer">
              <b>{t('terms_of_use')}</b>
            </a>{' '}
            {t('connect_box_and_the')}{' '}
            <a href={getLink('privacy')} target="_blank" rel="noreferrer">
              <b>{t('privacy_policy')}</b>
            </a>{' '}
            {t('connect_box_of_hypolake')}
          </p>
        </div>
        <div
          className={`connect-box-status ${
            statusConnect === t('connect_success')
              ? 'connect-box-status-success'
              : ''
          }`}
        >
          {statusConnect}
        </div>
      </div>
    </>
  )
}

export default ConnectBox
