import { useEffect } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
//
import routes from './routes/routes'
import i18n from './languages/i18n'

function App() {
  const verifyLanguage = () => {
    let lang = 'en'

    const { hash } = window.location
    const paramsIndex = hash.indexOf('?')
    const hashPage =
      paramsIndex > -1 ? hash.slice(hash.indexOf('#'), paramsIndex) : hash

    const query = new URLSearchParams(
      window.location.hash.replace(hashPage, '')
    )

    console.log(query.get('lang'))

    const queryLang = query.get('lang')

    if (queryLang) {
      lang = queryLang === 'pt' ? 'pt' : 'en'
    } else {
      lang = navigator.language || 'en'
    }
    console.log(lang)
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }

  useEffect(() => {
    verifyLanguage()
  }, [window.location])
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          {routes.map((data) => (
            <Route
              key={data.path}
              path={data.path}
              component={data.component}
            />
          ))}
        </Switch>
      </HashRouter>
    </div>
  )
}

export default App
