import Google from '../features/Google/Google'
import Home from '../features/Home/Home'
import Iframe from '../features/Iframe/iframe'
import TermsOfUse from '../features/TermsOfUse/TermsOfUse'

const routes = [
  {
    path: '/iframe',
    component: Iframe,
  },
  {
    path: '/google',
    component: Google,
  },
  {
    path: '/terms',
    component: TermsOfUse,
  },
  {
    path: '/',
    component: Home,
  },
]

export default routes
