import propTypes from 'prop-types'
import './index.scss'

function ConnectButton({ icon, text, backgroundStyles, onClick, disabled }) {
  return (
    <button
      type="button"
      className="connect-button"
      style={backgroundStyles || {}}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="connect-button-icon">
        <img src={icon} alt="icon" width={25} />
      </div>
      <span className="connect-button-text">{text}</span>
    </button>
  )
}

ConnectButton.propTypes = {
  icon: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  backgroundStyles: propTypes.shape({
    backgroundColor: propTypes.string,
    backgroundImage: propTypes.string,
    background: propTypes.string,
    color: propTypes.string,
  }).isRequired,
  disabled: propTypes.bool,
  onClick: propTypes.func.isRequired,
}

ConnectButton.defaultProps = {
  disabled: false,
}
export default ConnectButton
