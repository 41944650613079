import React from 'react'
import propTypes from 'prop-types'
import './styles.scss'

const Modal = ({ showModal, title, children, onClose, stretch }) =>
  showModal ? (
    <div className="modal">
      <div className={`modal-content ${stretch && 'modal-stretch'}`}>
        <div className="modal-header">
          <div>{title}</div>
          {onClose && (
            <button type="button" onClick={onClose} className="close">
              &times;
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  ) : null

Modal.propTypes = {
  showModal: propTypes.bool,
  title: propTypes.string,
  children: propTypes.arrayOf(propTypes.element),
  onClose: propTypes.func,
  stretch: propTypes.bool,
}

Modal.defaultProps = {
  showModal: true,
  title: null,
  children: [],
  onClose: null,
  stretch: false,
}

export default Modal
