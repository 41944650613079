import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ptLang from './pt'
import enLang from './en'
import esLang from './es'

const resources = {
  pt: {
    translation: ptLang,
  },
  pt_br: {
    translation: ptLang,
  },
  'pt-BR': {
    translation: ptLang,
  },
  en: {
    translation: enLang,
  },
  en_us: {
    translation: enLang,
  },
  'en-US': {
    translation: enLang,
  },
  es: {
    translation: esLang,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  keySeparator: false,
  nsSeparator: false,

  interpolation: {
    escapeValue: false,
  },
})

const links = {
  en: {
    home: 'https://hypolake.com/',
    product: 'https://hypolake.com/our-product/',
    resources: 'https://hypolake.com/resources/',
    plan: 'https://hypolake.com/plan/',
    contact: 'https://hypolake.com/contact/',
    privacy: 'https://hypolake.com/privacy-policy',
    terms_of_use: '/#/terms',
  },
  pt: {
    home: 'https://hypolake.com/pt/pagina-principal/',
    product: 'https://hypolake.com/pt/nossos-produtos/',
    resources: 'https://hypolake.com/pt/recursos/',
    plan: 'https://hypolake.com/pt/planos/',
    contact: 'https://hypolake.com/pt/contato/',
    privacy: 'https://hypolake.com/pt/politica-de-privacidade/',
    terms_of_use: '/#/terms',
  },
}

export const getLink = (key) => {
  const lang = ['en', 'en_US', 'en-US'].includes(i18n.language) ? 'en' : 'pt'

  return links[lang][key] || links.en[key]
}
export default i18n
